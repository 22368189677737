<template>
	<v-dialog v-model="meetingRoomsDialog"
		max-width="900px"
		persistent
	>
		<v-card style="position: relative;">
			<v-card-title class="light--text"
				:style="`background: linear-gradient(90deg, ${$vuetify.theme.themes.light.primary} 0%, ${$vuetify.theme.themes.light.secondary} 100%)`"
				style="position: sticky; top: 0; width: 100%; z-index: 1000;"
			>
				<span class="headline">Salas de reunião</span>
				<v-spacer></v-spacer>
				<v-tooltip top>
					<template v-slot:activator="{on}">
						<v-btn v-on="on"
							class="pa-0"
							@click="$emit('setMeetingRoomsDialog', false)"
							min-width="48px"
							text dark
						>
							<v-icon color="light">mdi-close</v-icon>
						</v-btn>
					</template>
					<span>Fechar</span>
				</v-tooltip>
			</v-card-title>
			<v-card-text class="py-4">
				<v-container fluid>
					<v-row class="d-flex justify-center">
						<v-col cols="12" v-for="n in 8" :key="n" class="d-flex justify-center">
								<a class="text-decoration-none"
									:href="anchorNavigate(n)"
									@click="navigate(n, $event)"
									style="width: 100%;"
								>
									<v-card elevation="2">
										<v-card-title class="text-h5">
											{{ `Sala de reunião 0${n}`}}
											<!-- <v-spacer></v-spacer>
											<div class="d-flex align-center">
												<span class="text-caption mr-2">Status</span>
												<v-badge inline color="success"></v-badge>
											</div> -->
										</v-card-title>
									</v-card>
								</a>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<!-- <div class="light" style="position: sticky; bottom: 0; width: 100%;">
				<v-divider class="mx-4"></v-divider>
				<v-card-actions class="px-6 py-6">
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						@click="passwordDialog = true"
					>
						Reservar uma sala
					</v-btn>
				</v-card-actions>
			</div> -->
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
// import configs from '@/configs'

export default {
    name: 'MeetingRooms',

	props: {
		meetingRoomsDialog: { type: Boolean, required: true }
	},

	computed: {
		...mapGetters('auth', ['getUser'])
	},

    data() {
		return {
			modules: [],
			env: null,
			hash: null,
			loading: true,
		}
	},

    created() {
		this.loading = true
    },

    methods: {
		...mapMutations('auth', ['resetState']),

		setUrl(subdomain) {
            let sys = this.$store.state.system.system.toLowerCase()
            // let mod = this.$store.state.system.module.toLowerCase()
			// let url = `https://fan.znaptech.com/jitsi.html?meeting_room=${sys}.${mod}0${subdomain}&email=${this.getUser.email}&name=${this.getUser.name}`
			let url = `https://${ sys === 'fan' ? 'fan.jitsi.' : '' }znaptech.com/jitsi.html?meeting_room=${ sys === 'fan' ? 'fan' : 'znap' }0${subdomain}&email=${this.getUser.email}&name=${this.getUser.name}`
			return url
		},

		anchorNavigate(subdomain) {
			let url = this.setUrl(subdomain)
			return url
        },

        navigate(subdomain, e) {
			e.preventDefault()
			let url = this.setUrl(subdomain)
            window.open(url)
        },
    }
}
</script>

<style>
</style>